import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Container,
  Grid,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ACCOUNT_ID,
  CREATED_BY_ID,
  CREATED_BY_NAME,
  Pages,
  PENDING_STATUS,
} from "../../constants/enums";
import useFormValidations from "../../hooks/useFormValidations";
import ButtonLoader from "../UI/ButtonLoader";
import FormField from "../UI/FormFields/FormFields";
import useFormFields from "../../hooks/useFormFields";
import { Booking } from "../../models/booking";
import Separator from "../Separator/Separator";
import Footer from "../Footer/Footer";
import { setBooking, setView } from "../../store/ducks/app";
import { isDateInPast } from "../../helpers/utils";
import useApp from "../../hooks/useApp";
import MainLoader from "../MainLoader";

interface Props {
  name: string;
}

const FieldName: React.FC<Props> = ({ name }) => {
  return (
    <Typography variant="body1" align="left" style={{ color: "#DACAA7" }}>
      {name}
    </Typography>
  );
};

interface FormProps {
  venue: any;
  loading: boolean;
}

const BookingForm: React.FC<FormProps> = ({ venue, loading }) => {
  const { showError } = useApp();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [successBar, setSuccessBar] = useState(false);

  const { bookingFields, userInfoFields } = useFormFields({
    slug: Pages.BOOKING,
  });
  const { getValidationSchema } = useFormValidations(Pages.BOOKING);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<any>({ resolver: yupResolver(getValidationSchema()) });

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    try {
      // Date validation
      if (data && data.date) {
        const result = isDateInPast(data.date);
        if (result) {
          showError("Not possible to reserve on a previous date.");
          return;
        }
      }

      // Prepare Booking Object
      data.mobile = data.mobile.replace(/\s/g, "");
      const phoneNumber =
        data.mobile.slice(0, 1) !== "0" ? `0${data.mobile}` : data.mobile;

      let comments: string[] = [];
      if (data.area) {
        comments.push(`Area: ${data.area}`);
      }
      if (data.area) {
        comments.push(`Meal: ${data.meal}`);
      }
      if (data.note) {
        comments.push(data.note);
      }
      if (data.coupon) {
        comments.push(`Promocode: ${data.coupon}`);
      }

      const bookingData: Booking = {
        conceptID: venue ? venue.id : "",

        accompaniedCount: data ? data.pax : 1,
        date: data ? data.date : new Date(),
        timeSlotID: data ? data.time : "",
        timeSlots: data ? [data.time] : [],

        mainGuest: "",
        customerGroup: "normal",
        customerName: data ? data.name : "",
        customerPhone: phoneNumber,
        email: data ? data.email.replace(/\s/g, "") : "",

        comments: comments,
        lastComment: comments.length > 0 ? comments[comments.length - 1] : "",

        tables: [],
        eventGuests: [],
        depositValue: "0",
        channel: "mobile app",
        statusID: PENDING_STATUS,

        deleted: "0",
        createdAt: new Date().toLocaleString(),

        // Static Values
        createdByID: CREATED_BY_ID,
        createdByName: CREATED_BY_NAME,
        accountID: ACCOUNT_ID,
      };

      dispatch(setBooking(bookingData));
      dispatch(setView("bookingOTP"));
    } catch (error) {
      console.error("Error parsing booking data:", error);
      // Show Error Message
      setOpen(true);
      showError(error);
    }
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          width: "90%",
          marginTop: "40px",
        }}
      >
        <Typography variant="h4" align="center" style={styles.title}>
          Reserve a Table
        </Typography>

        {!loading && venue ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={2}>
              {bookingFields(venue).map((field: any) => (
                <Grid
                  container
                  columnSpacing={2}
                  style={styles.formRow}
                  key={field.name}
                >
                  <Grid item xs={2} lg={2} md={2}>
                    <FieldName name={field.label}></FieldName>
                  </Grid>
                  <Grid item xs={10} lg={10} md={10}>
                    <FormField
                      key={field.name}
                      control={control}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      defaultValue={field.defaultValue}
                      options={field.options}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      disabled={field.disabled}
                      hidden={field.hidden}
                    />
                  </Grid>
                </Grid>
              ))}
              {/* Separator line CSS */}
              <Separator></Separator>

              {userInfoFields().map((field: any) => (
                <Grid
                  container
                  columnSpacing={2}
                  style={styles.formRow}
                  key={field.name}
                >
                  <Grid item xs={2} lg={2} md={2}>
                    <FieldName name={field.label}></FieldName>
                  </Grid>
                  <Grid item xs={10} lg={10} md={10}>
                    <FormField
                      key={field.name}
                      control={control}
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      defaultValue={field.defaultValue}
                      options={field.options}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      disabled={field.disabled}
                      hidden={field.hidden}
                    />
                  </Grid>
                </Grid>
              ))}

              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disableElevation
                  endIcon={isSubmitting && <ButtonLoader />}
                  disabled={isSubmitting}
                  style={styles.submitButton}
                >
                  Book Now
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <MainLoader></MainLoader>
        )}

        {/* Footer */}
        <Footer></Footer>

        {/* Success Message */}
        <Snackbar
          open={successBar}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setSuccessBar(false);
            }}
            severity="success"
          >
            Your reservation was successfully created.
          </Alert>
        </Snackbar>

        {/* Error Message */}
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="error"
          >
            Unfortunately, we were unable to complete your reservation. Please
            try again!
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

const styles = {
  title: {
    marginTop: "1vh",
    marginBottom: "3vh",
    color: "#DACAA7",
    fontSize: "30px",
    fontFamily: "Cormorant Garamond",
    fontStyle: "normal",
    lineHeight: "normal",
  },
  formRow: {
    marginTop: "1vh",
  },
  formInput: {
    border: "1px solid white",
    borderRadius: "5px",
    paddingLeft: "15px",
    height: "6vh",
    color: "white",
  },
  submitButton: {
    minHeight: "50px",
    minWidth: "220px",
    marginTop: "1vh",
    marginBottom: "3vh",
    borderRadius: "0px",
    border: "0.3px solid #dacaa7",
    background:
      "linear-gradient(91deg, #5E273A -4.95%, rgba(170, 52, 13, 0.00) 105.66%)",
  },
};

export default BookingForm;
