import "./FormFields.css";
import { FC, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";

import { capitalizeFirstLetter, theme } from "../../../helpers/utils";
import TimePickerValue from "../TimePickerValue/TimePickerValue";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { Select } from "@mui/material";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  type: string;
  autoFocus: boolean;
  defaultValue: any;
  register: any;
  setValue: any;
  errors: any;
  control: any;
  options?: any;
  disabled?: boolean;
  hidden?: any;
  resource?: any;
  slug?: any;
}

const FormField: FC<Props> = ({
  name,
  label,
  placeholder,
  type,
  autoFocus,
  defaultValue,
  register,
  setValue,
  errors,
  control,
  options,
  disabled,
  hidden,
  resource,
  slug,
}) => {
  const [currentValue, setCurrentValue] = useState<any>(defaultValue ?? "");

  const renderField = () => {
    switch (type) {
      case "multiLanguages":
        return (
          <TextField
            fullWidth
            size="small"
            autoFocus={autoFocus}
            value={currentValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentValue(event.target.value);
              setValue(name, event.target.value);
            }}
            placeholder={capitalizeFirstLetter(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "textField":
        return (
          <TextField
            fullWidth
            size="small"
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(name)}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "InputBase":
        return (
          <InputBase
            fullWidth
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(placeholder)}
            {...register(name)}
            error={errors[name] ? true : false}
            inputProps={{ min: 0 }}
            disabled={disabled}
            style={styles.formField}
          />
        );
      case "password":
        return (
          <TextField
            fullWidth
            size="small"
            type="password"
            autoFocus={autoFocus}
            placeholder={capitalizeFirstLetter(name)}
            defaultValue={defaultValue}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            hidden={hidden}
          />
        );
      case "number":
        return (
          <InputBase
            fullWidth
            type="number"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(placeholder)}
            {...register(name)}
            error={errors[name] ? true : false}
            inputProps={{ min: 0 }}
            disabled={disabled}
            style={styles.formField}
          />
        );
      case "phoneNumber":
        return (
          <InputBase
            fullWidth
            type="tel"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(placeholder)}
            {...register(name)}
            error={errors[name] ? true : false}
            inputProps={{ min: 0, inputMode: "tel" }}
            disabled={disabled}
            style={styles.formField}
          />
        );
      case "date":
        return (
          <InputBase
            fullWidth
            type="date"
            defaultValue={defaultValue}
            placeholder={capitalizeFirstLetter(placeholder)}
            {...register(name)}
            error={errors[name] ? true : false}
            inputProps={{ min: 0 }}
            disabled={disabled}
            style={styles.formField}
          />
        );
      case "datePicker":
        return (
          <Controller
            control={control}
            name="date-input"
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={capitalizeFirstLetter(name)}
                  {...register(name)}
                  error={errors[name] ? true : false}
                  disabled={disabled}
                  hidden={hidden}
                  minDate={dayjs(new Date().toLocaleDateString())}
                  className="picker"
                  backgroundColor="#fff"
                  sx={{ borderColor: "#fff" }}
                />
              </LocalizationProvider>
            )}
          />
        );
      case "select":
        return options ? (
          <Select
            fullWidth
            defaultValue={defaultValue}
            {...register(name)}
            error={errors[name] ? true : false}
            disabled={disabled}
            autoFocus={autoFocus}
            style={{
              border: `1px solid #dacaa7`, // Set the border color to white
              color: "#FFFFFF", // Set the text color to white
              borderRadius: "0px",
            }}
          >
            {options.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ) : null;

      case "checkbox":
        return (
          <Box sx={{ mt: -1 }}>
            <Checkbox
              sx={{ marginLeft: "-11px" }}
              defaultChecked={defaultValue}
              {...register(name)}
              disabled={disabled}
              hidden={hidden}
            />
          </Box>
        );
      case "autocomplete":
        return (
          <Autocomplete
            defaultValue={defaultValue}
            onChange={(e: any, value: string) => setValue(name, value)}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            options={options}
            disabled={disabled}
            getOptionLabel={(option: any) => option.label}
            renderInput={(params) => <TextField {...params} size="small" />}
            hidden={hidden}
          />
        );

      case "time":
        return (
          <Box sx={{ mt: -1 }}>
            <TimePickerValue
              name={name}
              defaultValue={defaultValue}
              setValue={setValue}
              register={register}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {/* {type !== "checkboxes" && type !== "image" && !hidden && (
        <Typography
          component={"label"}
          htmlFor={name}
          sx={{ mb: "4px", display: "inline-block" }}
        >
          {label}
        </Typography>
      )} */}
      {!hidden && renderField()}
      <Typography color="error" sx={{ fontSize: 14 }}>
        {errors[name]?.message}
      </Typography>
    </Box>
  );
};

const styles = {
  formField: {
    border: `1px solid #dacaa7`, // Set the border color to white
    color: "#FFFFFF", // Set the text color to white
    padding: theme.spacing(1), // Add padding as needed
    "&:disabled": {
      // Customize styles for disabled state if needed
      opacity: 0.6,
    },
  },
};

export default FormField;
